<template>
    <Dialog :draggable="false" :modal="true" :style="{width: '600px'}" :visible="showModal" header="Certificado"
            position="top">
        <form id="formCertificado" ref="form" @submit="save">
            <div class="p-fluid grid">
                <div class="field col-5 mb-0">
                    <label for="certificado">Certificado A1</label>
                    <FileUpload :maxFileSize="10485760" accept=".pfx" auto chooseLabel="Selecionar..."
                                class="block" customUpload
                                invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb." mode="basic"
                                @uploader="setFile"/>
                </div>
                <div class="field col-5 mb-0">
                    <label for="password">Senha</label>
                    <InputText id="password" v-model="form.password" autocomplete="off"
                               type="password"/>
                </div>
                <div class="field col-2 mb-0">
                    <label>&nbsp;</label>
                    <Button :disabled="!form.file || !form.password" label="Validar"
                            @click="validate"/>
                </div>
                <div v-if="form.cnpj || (!form.cnpj && !form.cpf)" class="field col-3 mb-0">
                    <label for="documento">CNPJ</label>
                    <p><strong>{{ form.cnpj || '-' }}</strong></p>
                </div>
                <div v-if="form.cpf" class="field col-3 mb-0">
                    <label for="documento">CPF</label>
                    <p><strong>{{ form.cpf }}</strong></p>
                </div>
                <div class="field col-4 mb-0">
                    <label for="name">Data validade</label>
                    <p><strong>{{ form.dataValidade || '-' }}</strong></p>
                </div>
            </div>
        </form>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="toggleFormCertificado"/>
            <Button :disabled="!isValidFile" form="formCertificado" icon="pi pi-check" label="Salvar" type="submit"/>
        </template>
    </Dialog>

    <div class="field col-3 mb-0">
        <Button
            :label="!certificado ? 'Adicionar certificado' : 'Atualizar certificado'"
            class="p-button-rounded" @click="toggleFormCertificado"/>
    </div>
    <div class="field col-3 mb-0">
        <p>Data de validade: <strong>{{
                formatDate(certificado?.dataValidade)
            }}</strong></p>
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import { mCNPJ, mCPF, mDate } from '../../../utils/Mask';

export default {
    name: 'FormCertificado',
    props: [
        'certificado',
        'companyId',
        'customerId'
    ],
    data() {
        return {
            form: {},
            showModal: false,
            isValidFile: false
        };
    },
    methods: {
        setFile(event) {
            this.form.file = event.files[0];
        },
        async validate() {
            let formData = new FormData();
            formData.append('file', this.form.file);
            formData.set('password', this.form.password);

            try {
                const {data} = await getClientBase().post('/esocial/certificate/info', formData);

                this.form.cpf = mCPF(data.cpf);
                this.form.cnpj = mCNPJ(data.cnpj);
                this.form.dataValidade = mDate(data.dataValidade);

                this.isValidFile = true;

                this.$toast.add({severity: 'success', summary: 'Arquivo validado com sucesso', life: 3000});
            } catch (err) {
                const message = err?.response?.data?.message;

                this.isValidFile = false;

                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao carregar o arquivo',
                    life: 3000,
                    detail: message
                });
            }
        },
        async save() {
            const urlPath = this.companyId ? 'companies' : 'customers';
            const urlId = this.companyId ? this.companyId : this.customerId;

            let formData = new FormData();
            formData.append('file', this.form.file);
            formData.set('password', this.form.password);

            try {
                let url = `/esocial/${urlPath}/${urlId}/certificate`;
                await getClientBase().put(url, formData);

                this.$toast.add({severity: 'success', summary: 'Arquivo salvo com sucesso', life: 3000});
                this.toggleFormCertificado();
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o certificado',
                    life: 3000,
                    detail: message
                });
            }
        },
        toggleFormCertificado() {
            this.showModal = !this.showModal;
        },
        formatDate(date) {
            return mDate(date);
        }
    }
};
</script>

<style scoped>

</style>
